var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "team-container" }, [
      _c("div", { staticClass: "team-bio" }, [
        _c("div", { staticClass: "team-name" }, [
          _vm._v(_vm._s(_vm.selectedTeam.team_name)),
        ]),
        _c("div", { staticClass: "team-experience" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.selectedTeam.experience
                  ? _vm.selectedTeam.experience.title
                  : ""
              ) +
              "\n      "
          ),
        ]),
        _c("div", { staticClass: "rating-wrap" }, [
          _c("div", { staticClass: "team-rating" }, [
            _vm._v(_vm._s(_vm.selectedTeam.grupa_score) + ".0 ⭐"),
          ]),
          _c("div", { staticClass: "team-speciality" }, [
            _c("img", {
              attrs: { src: "/img/onboarding/speciality-icon.svg" },
            }),
            _vm._v(
              "\n          " +
                _vm._s(_vm.checkUnderscore(_vm.selectedTeam.specialization)) +
                "\n        "
            ),
          ]),
          _c("div", { staticClass: "team-location" }, [
            _c("img", { attrs: { src: "/img/onboarding/location-icon.svg" } }),
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.selectedTeam.city ? _vm.selectedTeam.city + "," : ""
                ) +
                "\n          " +
                _vm._s(
                  _vm.selectedTeam.country ? _vm.selectedTeam.country : " "
                ) +
                "\n        "
            ),
          ]),
        ]),
        _c("div", { staticClass: "team-description" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.selectedTeam.bio ? _vm.selectedTeam.bio : "") +
              "\n      "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }