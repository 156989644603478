<template>
  <div>
    <Navbar />
    <!-- <transition name="modal-fade" mode="out-in"> -->
    <Loader key="1" v-if="selectedTeam == ''" />
    <div key="2" v-else class="page-container-fluid">
      <div class="page-navigator">
        <div class="go-back-div" @click="handleGoBack">
          <img src="/img/onboarding/back-icon.svg" />
          <span>Go Back</span>
        </div>
      </div>
      <TeamDetails v-bind="{ selectedTeam, submitTeam, spinner }" />
      <TeamMembers v-bind="{ selectedTeam }" />
      <TeamPortfolio v-bind="{ selectedTeam }" />
      <TeamReview />
      <div class="footer-section">
        <div class="footer-innner">
          <div class="team-badge">
            <img src="/img/onboarding/company/best-fit-2.svg" />
          </div>
          <div class="action-btn">
            <button class="grupa-blue-btn" @click="submitTeam">
              Meet This Team
              <img
                src="/img/lightbox/preloader.gif"
                class="button-spinner"
                v-show="spinner"
                style="margin-left: 5px"
              />
            </button>
          </div>
          <div class="pagination">
            <div
              v-for="(team, i) in teams"
              class="single-page"
              :key="i"
              :class="{
                'current-page': selectedTeam.user_name == team.user_name,
              }"
            >
              {{ i + 1 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Axios from "axios";
import Navbar from "@/components/Navbar/navbar";
import TeamDetails from "./team_details";
import TeamMembers from "./team_members";
import TeamPortfolio from "./team_portfolio";
import TeamReview from "./team_review";
import Loader from "@/components/general/loader2";
import { workWithTeam } from "@/api";

export default {
  props: {},
  components: {
    Navbar,
    TeamDetails,
    TeamMembers,
    TeamPortfolio,
    TeamReview,
    Loader,
  },
  data: () => ({
    selectedTeam: "",
    spinner: false,
    loadCalendly: true,
    showCalendly: false,
  }),

  created() {
    let userName = this.$route.params.team_name;
    this.getSelectedTeam(userName);
  },
  methods: {
    ...mapActions("selectTeamForProject", [
      "updatechosenName",
      "updateSelectedPlan",
    ]),
    getSelectedTeam(userName) {
      workWithTeam(userName)
        .then((response) => {
          this.selectedTeam = response.data.data;
          this.selectedTeam.members = JSON.parse(
            JSON.stringify(response.data.data.members)
          );
          this.$developConsole(response.data, "Work With Team");
        })
        .catch((err) => {
          this.$developConsole("Error Team", err.response);
        });
    },
    switchTeam(teamDetails) {
    
      const team = teamDetails.user_name;
      teamDetails.price ? this.updateSelectedPlan(teamDetails.price) : null;
      this.updatechosenName(team);
      this.selectedTeam = "";
      this.getSelectedTeam(team);
    },
    handleGoBack() {
      this.$router.push({
        name: "project_workspace",
        params: { id: this.$route.params.id },
      });
    },
  },
  computed: {
    ...mapState("selectTeamForProject", ["selectedPlan", "availableTeams"]),
    teams() {
      return this.availableTeams;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-container-fluid {
}
.page-navigator {
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
  padding: 0% 5%;
}
.pagination {
  display: flex;
}
.single-page {
  padding: 6px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  height: 30px;
  cursor: pointer;
}
.current-page {
  background: #53585f;
  border-radius: 2px;
  color: #ffffff;
}
.footer-section {
  padding: 53px 5% 100px 5%;
  border-top: solid 1px #f7f7f8;
}
.footer-innner {
  display: flex;
  justify-content: space-between;
}
.action-btn {
  margin-top: 20px;
}
.schedule-meeting {
  text-align: center;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 24px;
  cursor: pointer;
}
.schedule-meeting span {
  color: #0781f2;
  cursor: pointer;
}
</style>