<template>
  <div>
    <div class="team-container">
      <div class="team-bio">
        <div class="team-name">{{ selectedTeam.team_name }}</div>
        <div class="team-experience">
          {{ selectedTeam.experience ? selectedTeam.experience.title : "" }}
        </div>
        <div class="rating-wrap">
          <div class="team-rating">{{ selectedTeam.grupa_score }}.0 ⭐</div>
          <div class="team-speciality">
            <img src="/img/onboarding/speciality-icon.svg" />
            {{ checkUnderscore(selectedTeam.specialization) }}
          </div>
          <div class="team-location">
            <img src="/img/onboarding/location-icon.svg" />
            {{ selectedTeam.city ? selectedTeam.city + "," : "" }}
            {{ selectedTeam.country ? selectedTeam.country : " " }}
          </div>
        </div>
        <div class="team-description">
          {{ selectedTeam.bio ? selectedTeam.bio : "" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shortName from "@/mixins/shortName";
export default {
  props: {
    selectedTeam: {
      type: Object,
    },
    submitTeam: {
      type: Function,
    },
    spinner: {
      type: Boolean,
    },
  },
  mixins: [shortName],
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
.team-container {
  margin: 61px 0px 64px 0px;
  display: flex;
  justify-content: space-between;
  padding: 0% 5%;
}
.team-bio {
  text-align: left;
  width: 628px;
}
.team-name {
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  color: #1b1e22;
}
.rating-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
  width: 504px;
}
.team-experience {
  font-size: 16px;
  line-height: 130%;
  color: #979da5;
}
.team-rating,
.team-speciality {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.team-location {
  color: #0781f2;
}
.team-description {
  margin-top: 64px;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
  width: 628px;
}
.team-badge {
  margin-top: 82px;
}
</style>
