var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "team-members" }, [
      _c("div", { staticClass: "section-wrap" }, [
        _c("div", { staticClass: "section-title" }, [
          _vm._v(
            "\n        TEAM (" +
              _vm._s(_vm.selectedTeam.members.length) +
              ")\n      "
          ),
        ]),
        _c("div", { staticClass: "direction-left" }, [
          _c("img", {
            attrs: { src: "/img/onboarding/direction-left.png", alt: "" },
            on: {
              click: function ($event) {
                return _vm.previous($event)
              },
            },
          }),
        ]),
        _c("div", { staticClass: "direction-right" }, [
          _c("img", {
            attrs: { src: "/img/onboarding/direction-right.png", alt: "" },
            on: {
              click: function ($event) {
                return _vm.next($event)
              },
            },
          }),
        ]),
        _c(
          "div",
          { staticClass: "team-wrapper", attrs: { id: "teamCarousel" } },
          _vm._l(_vm.selectedTeam.members, function (member, j) {
            return _c("div", { key: j, staticClass: "member-card" }, [
              _c("div", { staticClass: "profile-img-div" }, [
                _c("div", {
                  staticClass: "profile-banner",
                  style: {
                    backgroundImage:
                      "url(" +
                      (_vm.$imageUrl + member.profile_picture ||
                        _vm.defaultProfileImage) +
                      ")",
                  },
                }),
              ]),
              _c("div", { staticClass: "dev-name" }, [
                _vm._v(_vm._s(member.name)),
              ]),
              _c("div", { staticClass: "dev-role" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(member.team_role || "No role selected") +
                    "\n          "
                ),
              ]),
              _c("div", { staticClass: "dev-work-experience" }, [
                _vm._v("0 years work experience"),
              ]),
              _vm._m(0, true),
              member.framework_tools
                ? _c(
                    "div",
                    { staticClass: "dev-tools" },
                    _vm._l(
                      _vm.parseToJson(member.framework_tools),
                      function (stack, i) {
                        return member.framework_tools
                          ? _c("div", { key: i, staticClass: "stack-div" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(stack) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e()
                      }
                    ),
                    0
                  )
                : _c("div", { staticClass: "dev-tools" }),
              _c("div", { staticClass: "view-profile" }, [
                _vm._v("View Full Profile"),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dev-company" }, [
      _c("img", {
        attrs: { src: "/img/onboarding/company/company1.svg", alt: "" },
      }),
      _c("img", {
        attrs: { src: "/img/onboarding/company/company2.svg", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }