var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Navbar"),
      _vm.selectedTeam == ""
        ? _c("Loader", { key: "1" })
        : _c(
            "div",
            { key: "2", staticClass: "page-container-fluid" },
            [
              _c("div", { staticClass: "page-navigator" }, [
                _c(
                  "div",
                  {
                    staticClass: "go-back-div",
                    on: { click: _vm.handleGoBack },
                  },
                  [
                    _c("img", {
                      attrs: { src: "/img/onboarding/back-icon.svg" },
                    }),
                    _c("span", [_vm._v("Go Back")]),
                  ]
                ),
              ]),
              _c(
                "TeamDetails",
                _vm._b(
                  {},
                  "TeamDetails",
                  {
                    selectedTeam: _vm.selectedTeam,
                    submitTeam: _vm.submitTeam,
                    spinner: _vm.spinner,
                  },
                  false
                )
              ),
              _c(
                "TeamMembers",
                _vm._b(
                  {},
                  "TeamMembers",
                  { selectedTeam: _vm.selectedTeam },
                  false
                )
              ),
              _c(
                "TeamPortfolio",
                _vm._b(
                  {},
                  "TeamPortfolio",
                  { selectedTeam: _vm.selectedTeam },
                  false
                )
              ),
              _c("TeamReview"),
              _c("div", { staticClass: "footer-section" }, [
                _c("div", { staticClass: "footer-innner" }, [
                  _c("div", { staticClass: "team-badge" }, [
                    _c("img", {
                      attrs: { src: "/img/onboarding/company/best-fit-2.svg" },
                    }),
                  ]),
                  _c("div", { staticClass: "action-btn" }, [
                    _c(
                      "button",
                      {
                        staticClass: "grupa-blue-btn",
                        on: { click: _vm.submitTeam },
                      },
                      [
                        _vm._v("\n            Meet This Team\n            "),
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.spinner,
                              expression: "spinner",
                            },
                          ],
                          staticClass: "button-spinner",
                          staticStyle: { "margin-left": "5px" },
                          attrs: { src: "/img/lightbox/preloader.gif" },
                        }),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "pagination" },
                    _vm._l(_vm.teams, function (team, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "single-page",
                          class: {
                            "current-page":
                              _vm.selectedTeam.user_name == team.user_name,
                          },
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(i + 1) + "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }