var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "review-container" }, [
      _c("div", { staticClass: "section-wrapper" }, [
        _vm._m(0),
        _c("div", [
          _c("div", { staticClass: "section-title" }, [
            _vm._v("CLIENT REVIEWS"),
          ]),
          _c(
            "div",
            { staticClass: "review-wrapper" },
            _vm._l(3, function (i) {
              return _c("div", { key: i, staticClass: "review-card" }, [
                _c("div", { staticClass: "review-content" }, [
                  _vm._v(
                    "\n              “To those bred under an elaborate social order few such moments\n              of exhilaration can come as that which stands at the threshold\n              of wild travel.”\n            "
                  ),
                ]),
                _vm._m(1, true),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "medal-container" }, [
      _c("div", { staticClass: "section-title" }, [_vm._v("MEDALS")]),
      _c("div", { staticClass: "medal-div" }, [
        _c("img", {
          attrs: { src: "/img/onboarding/company/medal.svg", alt: "" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "author-rating" }, [
      _c("div", { staticClass: "author-name" }, [_vm._v("SAM WILLIAM")]),
      _c("div", { staticClass: "review-rating" }, [
        _c("img", {
          attrs: { src: "/img/onboarding/company/rating.svg", alt: "" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }