<template>
  <div>
    <div class="review-container">
      <div class="section-wrapper">
        <div class="medal-container">
          <div class="section-title">MEDALS</div>
          <div class="medal-div">
            <img src="/img/onboarding/company/medal.svg" alt="" />
          </div>
        </div>
        <div>
          <div class="section-title">CLIENT REVIEWS</div>
          <div class="review-wrapper">
            <div class="review-card" v-for="i in 3" :key="i">
              <div class="review-content">
                “To those bred under an elaborate social order few such moments
                of exhilaration can come as that which stands at the threshold
                of wild travel.”
              </div>
              <div class="author-rating">
                <div class="author-name">SAM WILLIAM</div>
                <div class="review-rating">
                  <img src="/img/onboarding/company/rating.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.review-container {
  border-top: solid 1px #f7f7f8;
  padding: 0% 5%;
  padding-bottom: 64px;
}
.section-wrapper {
  margin-top: 64px;
  display: flex;
  justify-content: space-between;
}
.section-title {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
}
.medal-div {
  margin-top: 24px;
}
.review-wrapper {
  width: 628px;
  /* display: flex; */
  display: -webkit-box;
  flex-wrap: nowrap;
  margin-top: 24px;
  overflow: hidden;
}
.review-card {
  background: #ffffff;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 16px;
  width: 412px;
  margin-right: 24px;
}
.review-content {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-align: left;
}
.author-name {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.author-rating {
  display: flex;
  justify-content: space-between;
  margin-top: 29px;
}
</style>
