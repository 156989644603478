<template>
  <div>
    <div class="team-members">
      <div class="section-wrap">
        <div class="section-title">
          TEAM ({{ selectedTeam.members.length }})
        </div>
        <div class="direction-left">
          <img
            src="/img/onboarding/direction-left.png"
            @click="previous($event)"
            alt=""
          />
        </div>
        <div class="direction-right">
          <img
            @click="next($event)"
            src="/img/onboarding/direction-right.png"
            alt=""
          />
        </div>
        <div class="team-wrapper" id="teamCarousel">
          <div
            class="member-card"
            v-for="(member, j) in selectedTeam.members"
            :key="j"
          >
            <div class="profile-img-div">
              <div
                class="profile-banner"
                :style="{
                  backgroundImage: `url(${
                    $imageUrl + member.profile_picture || defaultProfileImage
                  })`,
                }"
              ></div>
            </div>
            <div class="dev-name">{{ member.name }}</div>
            <div class="dev-role">
              {{ member.team_role || "No role selected" }}
            </div>
            <div class="dev-work-experience">0 years work experience</div>
            <div class="dev-company">
              <img src="/img/onboarding/company/company1.svg" alt="" />
              <img src="/img/onboarding/company/company2.svg" alt="" />
            </div>
            <div class="dev-tools" v-if="member.framework_tools">
              <div
                v-if="member.framework_tools"
                v-for="(stack, i) in parseToJson(member.framework_tools)"
                :key="i"
                class="stack-div"
              >
                {{ stack }}
              </div>
            </div>
            <div v-else class="dev-tools"></div>

            <div class="view-profile">View Full Profile</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appMixin from "@/mixins/appMixin";
export default {
  props: {
    selectedTeam: {
      type: Object,
    },
  },
  components: {},
  mixins: [appMixin],
  data: () => ({
    teamMembers: [
      {
        name: "Nas",
        role: "Frontend",
        img: "/img/onboarding/company/profile.jpeg",
      },
      {
        name: "Kay",
        role: "Backend",
        img: "/img/onboarding/company/img1.svg",
      },
      {
        name: "Nas",
        role: "Frontend",
        img: "/img/onboarding/company/profile.jpeg",
      },
      {
        name: "Kay",
        role: "Backend",
        img: "/img/onboarding/company/img1.svg",
      },
      {
        name: "Nas",
        role: "Frontend",
        img: "/img/onboarding/company/profile.jpeg",
      },
      {
        name: "Kay",
        role: "Backend",
        img: "/img/onboarding/company/img1.svg",
      },
      {
        name: "Nas",
        role: "Frontend",
        img: "/img/onboarding/company/profile.jpeg",
      },
      {
        name: "Kay",
        role: "Backend",
        img: "/img/onboarding/company/img1.svg",
      },
    ],
    tools: [
      "Javascript",
      "AngularJs",
      "Python",
      "Bubble.is",
      "React",
      "Vue.js",
    ],
    frame: 3,
    count: 0,
  }),

  created() {},
  methods: {
    previous(event) {
      event.stopPropagation();
      this.count--;
      this.count = this.count--;
      this.sliderPortfolio("previous");
    },

    next(event) {
      event.stopPropagation();
      this.count++;
      this.count = this.count++;
      this.sliderPortfolio("next");
    },
    sliderPortfolio(position) {
      let el = document.getElementById("teamCarousel");
      let pos = 0;
      let id = setInterval(frame, 5);
      let num = this.selectedTeam.members.length - this.frame;
      let width = 400;
      let resize = num * width;
      let check = position == "reset" ? resize : width;
      var obj = this;
      function frame() {
        if (pos == check) {
          clearInterval(id);
        } else {
          pos += 5;
          if (position == "next") {
            el.scrollLeft += 5;
          } else {
            el.scrollLeft -= 5;
          }
          //  obj.checkScrolLeft(el.scrollLeft);
        }
      }
    },
  },
  computed: {},
};
</script>
<style scoped>
.team-members {
  border-top: solid 1px #f7f7f8;
  padding: 0% 5%;
  padding-bottom: 64px;
}
.section-wrap {
  margin-top: 64px;
  position: relative;
}
.section-title {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
}
.team-wrapper {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 24px;
  overflow: hidden;
}
/* Member card style */
.member-card {
  width: 231px;
  text-align: left;
  margin-right: 32px;
}
.profile-img-div {
  width: 231px;
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 212px;
}
.profile-banner {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: top;
}
.dev-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 16px;
}
.dev-role {
  margin-top: 2px;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.dev-work-experience {
  margin-top: 24px;
  font-size: 12px;
  line-height: 140%;
  color: #1fa564;
}
.dev-company {
  margin-top: 6px;
  display: flex;
}
.dev-tools {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  min-height: 68px;
}
.stack-div {
  background: #f7f7f8;
  border-radius: 30px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  margin: 0px 8px 8px 0px;
  height: 25px;
}
.view-profile {
  margin-top: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
}
.direction-right {
  position: absolute;
  top: 32%;
  right: -21px;
  z-index: 700;
  cursor: pointer;
}
.direction-left {
  position: absolute;
  top: 32%;
  left: -21px;
  z-index: 700;
  cursor: pointer;
}
</style>
